.react-datepicker__year-dropdown-container *,
.react-datepicker__month-dropdown-container * {
    font-family: inherit !important;
    font-weight: 400 !important;
    font-size: 14px !important;;
    color: #2D2E2F !important;
}

.react-datepicker__year-dropdown-container *,
.react-datepicker__month-dropdown-container * {
    border-radius: 5px;
    cursor: pointer;
}

.showDropdown * .react-datepicker__current-month {
    display: none;
}

.requester-info-company-select {
    width: 290px;
}

.company-with-address-option:hover {
    background-color: #F0F6FF;
}

.underline-on-hover:hover {
    text-decoration: underline;
}
