#okta-sign-in .o-form-head, #okta-sign-in .auth-header, #okta-sign-in .custom-checkbox {
    display: none;
}

#okta-sign-in, .auth-content  {
    border: None !important;
    box-shadow: None !important;
}

#okta-sign-in .o-form-fieldset-container {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

/* new brand primary button styles */
#okta-sign-in .button-primary {
    background: #006CCF !important; 
    color: white !important;
}

#okta-sign-in .button-primary:hover {
    background: #024D95 !important;
}

/* new brand link styles */
#okta-sign-in a {
    color: #006CCF !important;
    font-weight: 500 !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 14px !important;
}

.okta-widget-div {
    margin-top: -25% !important;
}

#okta-sign-in.auth-container.main-container {
    margin-top: 25% !important;
}
